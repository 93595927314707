import { useEffect, useState } from "react";
import useSlideOver from "../components/slideOver/useSlideOver";
import ApiDatabase from "../server";

const useTimeSheet = ({
  idTimeSheet,
  idMission,
  dateMission,
  setSlideOverDataReturn,
}) => {
  const { toggleSlideOver } = useSlideOver();
  const [timeSheet, setTimeSheet] = useState([]);
  const [errorTimeSheet, setErrorTimeSheet] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch timesheet data when idTimeSheet is provided
  useEffect(() => {
    if (idTimeSheet) {
      setIsWaiting(true);
      ApiDatabase.getTimeSheet(
        { id: idTimeSheet },
        (data) => {
          if (data.timeSheet?.periods.length) {
            const sortedPeriods = data.timeSheet.periods.sort(
              (a, b) => a.hours.position - b.hours.position
            );
            sortedPeriods.forEach((period, i) => {
              setTimeSheet((prev) => {
                const temp = [...prev];
                temp[i] = { start: period.hours.start, end: period.hours.end };
                return temp;
              });
              setErrorTimeSheet((prev) => {
                const temp = [...prev];
                temp[i] = { start: "", end: "" };
                return temp;
              });
            });
          }
          setIsWaiting(false);
        },
        (err) => {
          console.error("Erreur lors de la récupération du timeSheet", err);
          setIsWaiting(false);
        }
      );
    }
  }, [idTimeSheet]);

  // Adds a new period if there are less than 5
  const addPeriod = () => {
    if (timeSheet.length < 5) {
      setTimeSheet((prev) => [...prev, { start: "", end: "" }]);
      setErrorTimeSheet((prev) => [...prev, { start: "", end: "" }]);
    }
  };

  // Removes a period at the specified index
  const removePeriod = (index) => {
    setTimeSheet((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
    setErrorTimeSheet((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  // Updates the 'start' time of a period at the given index
  const updatePeriodStart = (index, value) => {
    setTimeSheet((prev) => {
      const temp = [...prev];
      if (temp[index]) {
        temp[index].start = value;
      }
      return temp;
    });
  };

  // Updates the 'end' time of a period at the given index
  const updatePeriodEnd = (index, value) => {
    setTimeSheet((prev) => {
      const temp = [...prev];
      if (temp[index]) {
        temp[index].end = value;
      }
      return temp;
    });
  };

  // On blur of a time input, reassemble the periods and check for duplicate values
  const handleTimeInputBlur = () => {
    setTimeout(() => {
      // Preserve the current focused value if any
      const activeEl = document.activeElement;
      let preserveFocusValue = "";

      if (
        activeEl &&
        activeEl.tagName === "INPUT" &&
        activeEl.type === "time" &&
        activeEl.value !== ""
      ) {
        preserveFocusValue = activeEl.value;
      }
      if (
        activeEl &&
        activeEl.tagName === "INPUT" &&
        activeEl.type === "time" &&
        activeEl.value === ""
      ) {
        return;
      }

      // Collect all time values from the periods
      let times = [];
      timeSheet.forEach((period) => {
        if (period.start) times.push(period.start);
        if (period.end) times.push(period.end);
      });
      times.sort((a, b) => a.localeCompare(b));

      // Reconstruct periods in pairs of times
      const newPeriods = [];
      for (let i = 0; i < times.length; i += 2) {
        newPeriods.push({
          start: times[i],
          end: times[i + 1] || "",
        });
      }
      setTimeSheet(newPeriods);

      // Check for duplicate time values across both 'start' and 'end'
      const newErrors = newPeriods.map(() => ({ start: "", end: "" }));
      const countValue = (value) =>
        newPeriods.reduce((acc, curr) => {
          return (
            acc + (curr.start === value ? 1 : 0) + (curr.end === value ? 1 : 0)
          );
        }, 0);

      newPeriods.forEach((period, idx) => {
        if (period.start && countValue(period.start) > 1) {
          newErrors[idx].start = "Cette heure est dupliquée.";
        }
        if (period.end && countValue(period.end) > 1) {
          newErrors[idx].end = "Cette heure est dupliquée.";
        }
      });
      setErrorTimeSheet(newErrors);

      // Restore focus to the previously focused input if applicable
      if (preserveFocusValue !== "") {
        setTimeout(() => {
          const inputs = document.querySelectorAll('input[type="time"]');
          for (let input of inputs) {
            if (input.value === preserveFocusValue) {
              input.focus();
              break;
            }
          }
        }, 50);
      }
    }, 200);
  };

  // Handles submission by creating or updating the timesheet via the API
  const handleSubmit = () => {
    setIsLoading(true);

    // Reorder times in ascending order
    let times = [];
    timeSheet.forEach((period) => {
      if (period.start) times.push(period.start);
      if (period.end) times.push(period.end);
    });
    times.sort((a, b) => a.localeCompare(b));

    // Pair consecutive times into periods
    const newPeriods = [];
    for (let i = 0; i < times.length; i += 2) {
      newPeriods.push({
        start: times[i],
        end: times[i + 1] || "",
      });
    }
    setTimeSheet(newPeriods);

    // Check for duplicate times
    const newErrors = newPeriods.map(() => ({ start: "", end: "" }));
    const countValue = (value) =>
      newPeriods.reduce(
        (acc, curr) =>
          acc + (curr.start === value ? 1 : 0) + (curr.end === value ? 1 : 0),
        0
      );
    newPeriods.forEach((period, idx) => {
      if (period.start && countValue(period.start) > 1) {
        newErrors[idx].start = "Cette heure est dupliquée.";
      }
      if (period.end && countValue(period.end) > 1) {
        newErrors[idx].end = "Cette heure est dupliquée.";
      }
    });
    setErrorTimeSheet(newErrors);

    // Filter out empty periods
    const filteredTimeSheet = newPeriods.filter(
      (period) => !(period.start === "" && period.end === "")
    );

    if (!idTimeSheet) {
      if (
        filteredTimeSheet.length === 0 ||
        filteredTimeSheet.some((period) => !period.start)
      ) {
        setIsLoading(false);
        return;
      }
      ApiDatabase.postCreateTimeSheetMission(
        { idMission, date: dateMission, timeSheet: filteredTimeSheet },
        (data) => {
          setIsLoading(false);
          setSlideOverDataReturn({
            idMission,
            date: dateMission,
            idTimeSheet: data.idTimeSheet,
            timeSheet: data.timeSheet,
            pause: data.pause,
            totalHours: data.totalHours,
            "totalHoursNight5%": data["totalHoursNight5%"],
            "totalHoursNight20%": data["totalHoursNight20%"],
          });
          toggleSlideOver();
        },
        (err) => {
          setIsLoading(false);
          console.error(err);
        }
      );
    } else {
      ApiDatabase.postUpdateTimeSheet(
        { idTimeSheet, timeSheet: filteredTimeSheet },
        (data) => {
          setIsLoading(false);
          setSlideOverDataReturn({
            idMission,
            date: dateMission,
            timeSheet: data.timeSheet,
            pause: data.pause,
            totalHours: data.totalHours,
            "totalHoursNight5%": data["totalHoursNight5%"],
            "totalHoursNight20%": data["totalHoursNight20%"],
          });
          toggleSlideOver();
        },
        (err) => {
          setIsLoading(false);
          console.error(err);
        }
      );
    }
  };

  return {
    timeSheet,
    errorTimeSheet,
    isWaiting,
    isLoading,
    addPeriod,
    removePeriod,
    updatePeriodStart,
    updatePeriodEnd,
    handleTimeInputBlur,
    handleSubmit,
  };
};

export default useTimeSheet;
