import React from "react";
import { Plus } from "../../../../../assets/icons";
import UseContext from "../../../../../hooks/useContext";
import useTimeSheet from "../../../../../hooks/useTimeSheet";
import { dateLong } from "../../../../../utils/Date";
import Button from "../../../../button";
import EmptyState from "../../../../EmptyState/EmptyState";
import SlideOverBody from "../../../component/body";
import SlideOverFooter from "../../../component/footer";
import SlideOverHeader from "../../../component/header";
import useSlideOver from "../../../useSlideOver";
import TimeSheetPeriod from "./TimeSheetPeriod";

const SlideOverUpdateTimeSheet = () => {
  const { slideOverData, setSlideOverDataReturn } = UseContext();
  const { idTimeSheet, idMission, dateMission } = slideOverData;
  const { toggleSlideOver } = useSlideOver();

  const {
    timeSheet,
    errorTimeSheet,
    isWaiting,
    isLoading,
    addPeriod,
    removePeriod,
    updatePeriodStart,
    updatePeriodEnd,
    handleTimeInputBlur,
    handleSubmit,
  } = useTimeSheet({
    idTimeSheet,
    idMission,
    dateMission,
    setSlideOverDataReturn,
  });

  // Determine if there are any errors to disable the Confirm button
  const hasErrors = errorTimeSheet.some(
    (error) => error.start !== "" || error.end !== ""
  );

  return (
    <>
      <SlideOverHeader
        className="slide-over-update-time-sheet-header"
        hide={toggleSlideOver}
      >
        <div className="capitalize">{dateLong(new Date(dateMission))}</div>
        <div className="text-sm text-gray-500">
          Modifiez les heures pointées par les profils.
        </div>
      </SlideOverHeader>
      <SlideOverBody className="slide-over-update-time-sheet-body">
        <div className="flex flex-col gap-4 h-full">
          {isWaiting ? (
            // Loading state skeleton
            <>
              <div className="flex justify-between items-center gap-2.5 animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-20"></div>
                <div className="h-6 bg-gray-200 rounded w-60"></div>
              </div>
              <div className="h-0.5 bg-gray-50 w-full"></div>
              <div className="flex justify-between items-center gap-2.5 animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-20"></div>
                <div className="h-6 bg-gray-200 rounded w-60"></div>
              </div>
            </>
          ) : (
            <>
              {timeSheet.length === 0 ? (
                // Empty state when there are no periods
                <div className="flex flex-col justify-center items-center h-full">
                  <EmptyState
                    type="mission_time_sheet_period"
                    custom={
                      <div className="w-fit my-6">
                        <Button
                          size="SM"
                          color="SECONDARY"
                          name="slide-over-update-time-sheet-body-add-periods"
                          onClick={addPeriod}
                        >
                          <div className="flex gap-1 items-center">
                            <div>
                              <Plus wh={20} color="#374151" />
                            </div>
                            <div>Ajouter un pointage</div>
                          </div>
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : (
                <>
                  {timeSheet.map((period, index) => (
                    <TimeSheetPeriod
                      key={index}
                      index={index}
                      period={period}
                      error={errorTimeSheet[index] || { start: "", end: "" }}
                      onChangeStart={updatePeriodStart}
                      onChangeEnd={updatePeriodEnd}
                      onBlur={handleTimeInputBlur}
                      onRemove={removePeriod}
                    />
                  ))}
                  <div className="w-fit">
                    <Button
                      size="SM"
                      color="SECONDARY"
                      name="slide-over-update-time-sheet-body-add-period"
                      onClick={addPeriod}
                    >
                      <div className="flex gap-1 items-center">
                        <div>
                          <Plus wh={20} color="#374151" />
                        </div>
                        <div>Ajouter</div>
                      </div>
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </SlideOverBody>
      <SlideOverFooter className="slide-over-update-time-sheet-footer">
        <Button size="SM" color="SECONDARY" onClick={toggleSlideOver}>
          Fermer
        </Button>
        <Button
          size="SM"
          color={isLoading || hasErrors ? "DISABLED" : "PRIMARY"}
          name="slide-over-update-time-sheet-footer-confirm"
          onClick={isLoading || hasErrors ? undefined : handleSubmit}
        >
          {isLoading ? "Confirmation..." : "Confirmer"}
        </Button>
      </SlideOverFooter>
    </>
  );
};

export default SlideOverUpdateTimeSheet;
