import React from "react";
import { CrossIcon } from "../../../../../assets/icons";
import Button from "../../../../button";
import StyledInput from "../../../../styledInput/StyledInput";

const TimeSheetPeriod = ({
  index,
  period,
  error,
  onChangeStart,
  onChangeEnd,
  onBlur,
  onRemove,
}) => {
  return (
    <div className="flex flex-col gap-3 slide-over-update-time-sheet-period-body">
      {/* Divider with delete button */}
      <div className="flex gap-2 items-center">
        <div className="h-0.5 bg-gray-50 w-full"></div>
        <div className="flex justify-end">
          <Button
            size="ICON"
            color="ICON"
            name="slide-over-update-time-sheet-body-delete-period"
            onClick={() => onRemove(index)}
          >
            <CrossIcon wh={20} color="#374151" />
          </Button>
        </div>
      </div>
      {/* Start time input */}
      <div className="flex justify-between items-center gap-2.5">
        <div>Entrée</div>
        <div className="w-full max-w-64">
          <StyledInput
            type="time"
            value={period.start}
            onChange={(e) => onChangeStart(index, e.target.value)}
            onBlur={onBlur}
            error={error.start}
          />
        </div>
      </div>
      <div className="h-0.5 bg-gray-50 w-full"></div>
      {/* End time input */}
      <div className="flex justify-between items-center gap-2.5">
        <div>Sortie</div>
        <div className="w-full max-w-64">
          <StyledInput
            type="time"
            value={period.end}
            onChange={(e) => onChangeEnd(index, e.target.value)}
            onBlur={onBlur}
            error={error.end}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeSheetPeriod;
